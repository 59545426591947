import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Loading } from '@/components'
import { useAnalytics, useAppDispatch } from '@/hooks'
import { sessionStorage } from '@/services'
import { logout } from '@/store'

const Logout = () => {
    const navigate = useNavigate()
    const { setUser } = useAnalytics()
    const dispatch = useAppDispatch()

    const handleLogout = () => {
        sessionStorage.delete('seen_banners')
        dispatch(logout())
        setUser()
        navigate('/login')
    }

    useEffect(() => {
        handleLogout()
    }, [])

    return <Loading/>
}

export default Logout
