import { useCallback } from 'react'
import ReactGA from 'react-ga4'

import { User } from '@/models'
import { gaEvent, setUserProperties } from '@/utils'


type TrackEvent = (action: string, category: string, label: string, value?: number) => void

const useAnalytics = (): { trackEvent: TrackEvent, setUser: (user?: User) => void } => {
    const trackEvent = useCallback((action: string, category: string, label: string, value = 1) => {
        gaEvent({
            action,
            category,
            label,
            value
        })
    }, [])

    const setUser = (user?: User) => {
        ReactGA.set({ userId: user?.id })
        setUserProperties()
    }

    return { trackEvent, setUser }
}

export default useAnalytics
