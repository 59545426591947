import { Company } from './Company'
import { Model } from './Model'
import { Badge } from '@/components'
import { api, Options, Params, Payload } from '@/services'
import {
    ArticleNotificationType,
    IdType,
    UserActivityType,
    UserDataType,
    UserPreferenceType,
    UserRoleType
} from '@/types'

const accountTypes = [
    { value: 'advisor', title: 'Advisor', type: 'user' },
    { value: 'administrator', title: 'Administrator', type: 'user' },
    { value: 'staff', title: 'Staff', type: 'user' },

    { value: 'contractor', title: 'Contractor Company', shortTitle: 'Contractor', type: 'company' },
    { value: 'contractor-user', title: 'Contractor User', type: 'company-user' },

    { value: 'software_affiliate', title: 'Software Affiliate Company', shortTitle: 'Software Affiliate', type: 'company' },
    { value: 'lender_affiliate', title: 'Lender Affiliate Company', shortTitle: 'Lender Affiliate', type: 'company' },

    { value: 'affiliate-user', title: 'Affiliate User', type: 'company-user' }
]

export class User extends Model {
    static endpoint = 'api/v1/users'

    raw: UserDataType

    name: string

    email: string

    phone?: string

    role: UserRoleType

    company?: Company

    status?: string

    job_title?: string

    preferred_features: string[]

    permissions: string[]

    article_notifications: ArticleNotificationType[]

    preferences?: UserPreferenceType

    activity?: UserActivityType

    last_online?: string

    email_verified_at?: Date


    constructor(data: UserDataType) {
        super(data)
        this.raw = data
        this.name = data.name?.capitalize()
        this.email = data.email
        this.phone = data.phone
        this.role = data.role?.toLowerCase() as UserRoleType
        this.company = data.company ? new Company(data.company) : undefined
        this.status = data.status
        this.job_title = data.job_title
        this.preferred_features = data.preferred_features || []
        this.permissions = data.permissions || []
        this.article_notifications = data.article_notifications
        this.preferences = data.preferences
        this.last_online = data.last_online
        this.email_verified_at = Model.toDate(data.email_verified_at)
    }

    static impersonateEmail: string = import.meta.env.VITE_IMPERSONATION_EMAIL


    get isAdmin() {
        return ['Administrator', 'administrator'].includes(this.role)
    }

    get isAffiliate() {
        return ['Conglomerate', 'conglomerate'].includes(this.role) || this.isSoftwareAffiliate || this.isLenderAffiliate
    }

    get isSoftwareAffiliate() {
        return this.company?.isSoftwareAffiliate || ['Software Affiliate', 'software_affiliate'].includes(this.role)
    }

    get isLenderAffiliate() {
        return this.company?.isLenderAffiliate || ['Lender Affiliate', 'lender_affiliate'].includes(this.role)
    }

    get isAllowedToCreateWarranty() {
        return !this.company?.monitoringStatus.isIncomplete &&
            (this.company?.unique_id?.startsWith('001') && this.company?.unique_id?.length === 18) &&
            [this.company?.battery_20_year_rate, this.company?.battery_30_year_rate]
                .every(rate => rate && rate !== 0)
    }

    get isSubsidiary() {
        return this.company?.isSubsidiary
    }

    get isContractor() {
        return ['Contractor', 'contractor'].includes(this.role)
    }

    get isStaff() {
        return ['Staff', 'staff'].includes(this.role)
    }

    get isAdvisor() {
        return ['Advisor', 'advisor'].includes(this.role)
    }

    get isAdminOrStaff() {
        return this.isAdmin || this.isStaff
    }

    get isAdminOrStaffOrAdvisor() {
        return this.isAdmin || this.isStaff || this.isAdvisor
    }

    get isAffiliateOrContractor() {
        return this.isAffiliate || this.isContractor
    }

    get accountType() {
        let type = { title: 'Unknown' }

        if (this.isAdminOrStaffOrAdvisor) {
            type = accountTypes.find(({ value }) => value === this.role) || type
        }
        if (this.isAffiliateOrContractor) {
            type = accountTypes.find(({ value }) => value === this.company?.type?.key) || type
        }
        return type.title
    }

    get preferredFeaturesBadges() {
        return this.preferred_features.map((item: string) => {
            switch (item) {
                case 'Monitoring': {
                    return <Badge key={item} className="text-purple-700 bg-purple-50">{item}</Badge>
                }
                case 'Invoicing': {
                    return <Badge key={item} className="text-success-700 bg-success-50">{item}</Badge>
                }
                default: {
                    return null
                }
            }
        })
    }

    static get allAccountTypes() {
        return accountTypes
    }

    static get userAccountTypes() {
        return accountTypes.filter(({ type }) => type === 'user')
    }

    static get companyAccountTypes() {
        return accountTypes.filter(({ type }) => type === 'company')
    }

    static get companyUserAccountTypes() {
        return accountTypes.filter(({ type }) => type === 'company-user')
    }

    static updatePreferencesSelf(payload: Payload) {
        return api.auth.updatePreferences(payload)
    }

    updatePreferences(payload: Payload) {
        return api.users.updatePreferences(this.id, payload)
    }

    updateOwnPreferences(payload: Payload) {
        return api.users.updateOwnPreferences(payload)
    }

    activities() {
        return api.users.activities(this.id)
    }

    updatePassword(payload: Payload) {
        return api.users.updatePassword(this.id, payload)
    }

    toggleVerification() {
        return api.users.toggleVerification(this.id)
    }

    static updatePasswordSelf(payload: Payload) {
        return api.auth.updatePassword(payload)
    }

    static impersonate(companyId: IdType, password: string) {
        return api.users.impersonate(companyId, password)
    }

    static updateSelf(payload: Payload, params?: Params, options?: Options) {
        return api.http.put(User.endpoint, payload, params, options)
    }
}
