import { useEffect, useRef, useState } from 'react'

import { Button, IconPlayCircle, IconStar04, IconStar08, Popup } from '@/components'
import { useAnalytics, useAuth } from '@/hooks'
import { AuthUser } from '@/models'
import { cookies } from '@/services'
import store, { login } from '@/store'

const DEFAULT_VIDEO = '/assets/videos/Watt%20a%20Year%20Solar%20Insure.mp4'

export const WhatAYearPopup = () => {
    if (import.meta.env.VITE_ENVIRONMENT === 'production' && new Date() < new Date('01/06/2025')) {
        return null
    }

    const auth = useAuth()
    const videoRef = useRef<HTMLVideoElement>(null)
    const { trackEvent } = useAnalytics()
    const [isOpen, setIsOpen] = useState(!auth.user?.preferences?.watched_watt_a_year_video)
    const [showPlayButton, setShowPlayButton] = useState(!auth.user?.preferences?.watched_watt_a_year_video)
    const setIsWatchedVideo = async () => {
        try {
            await auth.user?.updateOwnPreferences({ watched_watt_a_year_video: true })
        } finally {
            const user = await AuthUser.currentUser()
            await store.dispatch(login({ user, apiToken: cookies.get('api_token') }))
        }
    }

    useEffect(() => {
        if (isOpen && !auth.user?.preferences?.watched_watt_a_year_video) {
            setIsWatchedVideo()
        }
    }, [])

    const handleOpen = () => {
        setIsOpen(true)
        trackEvent('open_watt_a_year_popup', 'User Interaction', 'Open Watt a Year Popup')
    }

    const handleClose = () => {
        setIsOpen(false)
        trackEvent('close_watt_a_year_popup', 'User Interaction', 'Close Watt a Year Popup')
    }

    const handlePlay = () => {
        videoRef.current?.play()
    }

    const videoUrl = auth.user?.company.marketing_video_url || DEFAULT_VIDEO

    return <div className="z-10">
        <style>{`
            #gradient-border-button {
            
            }
            #gradient-border-button:after {
                content: '';
                height: 40px;
                width: calc(100% + 4px);
                position: absolute;
                z-index: -1;
                border-radius: 9px;
                background: linear-gradient(90deg, rgba(5,151,175,1) 0%, rgba(123,213,89,1) 20%, rgba(5,151,175,1) 40%, rgba(123,213,89,1) 60%, rgba(5,151,175,1) 80%, rgba(123,213,89,1) 100%);
                background-size: 400% 100%;
                animation: gradient-animation 5s linear infinite;
            }


            @keyframes gradient-animation {
                0% {
                    background-position: 0% 50%;
                }
                50% {
                    background-position: 100% 50%;
                }
                100% {
                    background-position: 0% 50%;
                }
            }
        `}
        </style>
        <Button
            id="gradient-border-button"
            design="btn-secondary-gray"
            className="gap-2 !border-0 !py-2 !px-3 !h-9 relative"
            onClick={handleOpen}
        >
            <IconStar04
                className="stroke-gray-900"
            />
            Watt a Year
        </Button>

        <Popup open={isOpen} onClose={handleClose} className="!max-w-[25.5rem] w-[calc(100vw-60px)]">
            <h2 className="flex text-gray-900 gap-2 items-center pr-4 mb-4">
                <IconStar08
                    size="xxl"
                    className="stroke-gray-900"
                />

                <div className="flex flex-col">
                    <span className="text-xl">Watt a Year</span>
                    <span className="text-xs whitespace-nowrap">Celebrating a Bright Year with Our Solar Providers!</span>
                </div>
            </h2>

            <div className="relative">
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                    controls
                    autoPlay
                    ref={videoRef}
                    onPlay={() => {
                        trackEvent('play_watt_a_year_video', 'User Interaction', 'Play Watt a Year video')
                        setShowPlayButton(false)
                    }}
                    onEnded={() => {
                        trackEvent('watched_watt_a_year_video', 'User Interaction', 'Watched Watt a Year video')
                        setShowPlayButton(true)
                    }}
                    onPause={() => {
                        setShowPlayButton(true)
                    }}
                    className="w-full min-h-[580px]"
                >
                    <source
                        src={videoUrl}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
                {showPlayButton && <button
                    className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2"
                    onClick={handlePlay}
                >
                    <IconPlayCircle className="stroke-white w-20 h-20"/>
                </button>}
            </div>
        </Popup>
    </div>
}
