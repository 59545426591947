import { useMemo, useState } from 'react'

import {
    Button,
    Form,
    FormChangeEvent,
    NotificationFormDataType,
    NotificationsForm,
    NotificationsFormDataType
} from '@/components'
import { useAuth } from '@/hooks'
import { User } from '@/models'

type UserEditFormProps = {
    user: User,
    onSuccess?: () => void
    onExit: () => void
}
const NotificationsEditForm = ({ user, ...props }: UserEditFormProps) => {
    const [processing, setProcessing] = useState(false)
    const auth = useAuth()
    const getForm = (): NotificationsFormDataType => {
        const settings = (user.preferences?.notification_settings || {}) as NotificationsFormDataType

        return {
            uploadStatus: {
                database: settings.uploadStatus?.database || false,
                mail: settings.uploadStatus?.mail || false
            },
            inactiveCompanies: user.isAdminOrStaffOrAdvisor ? {
                database: settings.inactiveCompanies?.database || false,
                mail: settings.inactiveCompanies?.mail || false
            } : undefined,
            credentials_update: user.isAdminOrStaffOrAdvisor ? {
                database: settings.credentials_update?.database || false,
                mail: settings.credentials_update?.mail || false
            } : undefined,
            company_updates: user.isAdminOrStaffOrAdvisor ? {
                database: settings.company_updates?.database || false,
                mail: settings.company_updates?.mail || false
            } : undefined,
            monthly_reports: user.isAdminOrStaffOrAdvisor ? {
                database: settings.monthly_reports?.database || false,
                mail: settings.monthly_reports?.mail || false
            } : undefined,
            fraud_review: user.isAdmin ? { mail: settings.fraud_review?.mail || false } : undefined
        }
    }
    const [form, setForm] = useState<NotificationsFormDataType>(getForm())

    const handleChange = (e: FormChangeEvent) => {
        const [key, type] = e.target.name.split('.')
        setForm(form => ({ ...form, [key]: { ...form[key] as NotificationFormDataType, [type]: e.target.checked } }))
    }

    const handleSubmit = async () => {
        setProcessing(true)
        try {
            if (user.id === auth.user?.id) {
                await User.updatePreferencesSelf({ notification_settings: form })
            } else {
                await user.updatePreferences({ notification_settings: form })
            }
            if (props.onSuccess) await props.onSuccess()
            props.onExit()
        } finally {
            setProcessing(false)
        }
    }

    const isDirty = useMemo(() => Object.keys(form).some(key => {
        const baseItem = getForm()[key] as NotificationFormDataType | undefined
        const curItem = form[key] as NotificationFormDataType | undefined
        return baseItem?.database !== curItem?.database || baseItem?.mail !== curItem?.mail
    }), [form, user])

    return <Form isDirty={isDirty} confirmation onSubmit={handleSubmit} className="grid lg:grid-cols-2 gap-6">
        <h3 className="font-semibold lg:col-span-2 font-body">Notification Preferences</h3>
        <div className="lg:col-span-2 grid lg:grid-cols-5 gap-3 lg:gap-0">
            <NotificationsForm
                form={form}
                onChange={handleChange}
            />
        </div>

        <div className="lg:col-span-2 flex flex-col gap-3 lg:flex-row justify-end">
            <Button design="btn-secondary-gray" hasError onClick={props.onExit} type="button">
                Exit Edit Mode Without Saving
            </Button>
            <Button processing={processing} disabled={!isDirty}>
                Save and Exit
            </Button>
        </div>
    </Form>
}

export default NotificationsEditForm
