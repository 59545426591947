import { useState } from 'react'

import {
    Card,
    DashboardAlert, EligibleCommissionSummary,
    IconReloadCw05,
    MonitoringSummary,
    PageHeader,
    RevenueByState,
    SalesByContractor,
    GraphReport, WhatAYearPopup
} from '@/components'
import { AuthLayout } from '@/containers'
import { useAuth, useAnalytics } from '@/hooks'

const AffiliateDashboard = () => {
    const { trackEvent } = useAnalytics()
    const auth = useAuth()
    const [processing, setProcessing] = useState({
        revenueByState: true,
        salesByContractor: true,
        salesReport: true,
        monitoringSummary: !!auth.user?.isLenderAffiliate,
        eligibleCommissionSummary: !!auth.user?.isSoftwareAffiliate
    })

    const [expand, setExpand] = useState({
        revenueByState: false,
        salesByContractor: false,
        salesReport: false
    })

    const getProcessing = () => Object.values(processing).some(item => item)

    const handleComplete = (name: string, value = false) => {
        setProcessing(processing => ({ ...processing, [name]: value }))
    }

    const handleHover = () => {
        trackEvent('hover_dashboard_reload_data', 'User Interaction', 'Reload Button Hover')
    }

    const handleReload = () => {
        trackEvent('click_dashboard_reload_data', 'User Interaction', 'Reload Button')
        setProcessing({
            revenueByState: true,
            salesByContractor: true,
            salesReport: true,
            monitoringSummary: !!auth.user?.isLenderAffiliate,
            eligibleCommissionSummary: !!auth.user?.isSoftwareAffiliate
        })
    }

    const handleExpand = (name: string, value = true) => {
        setExpand(expand => ({ ...expand, [name]: value }))
    }

    return <AuthLayout
        heading={<PageHeader
            title={<div className="flex gap-6">
                Dashboard
                <WhatAYearPopup/>
            </div>}
        />}
    >
        <div className="flex justify-end my-4">
            <button
                className="text-primary-700 stroke-primary-700 flex items-center"
                onClick={handleReload}
                onMouseOver={handleHover}
                onFocus={handleHover}
                disabled={getProcessing()}
            >
                <IconReloadCw05 className={`mr-2 ${getProcessing() ? 'animate-spin' : ''}`}/> Reload Data
            </button>
        </div>

        <DashboardAlert className="mb-4 lg:mb-6"/>

        <div className="grid grid-cols-12 gap-4 xl:gap-6">
            <Card className={`${expand.salesByContractor ? 'col-span-12' : 'col-span-12 xl:col-span-6'} ${auth.user?.isSoftwareAffiliate ? 'order-2' : ''} ${expand.revenueByState ? 'hidden' : ''}`}>
                <SalesByContractor
                    onChange={handleComplete}
                    onExpand={handleExpand}
                    processing={processing.salesByContractor}
                    isExpanded={expand.salesByContractor}
                    className="h-full flex flex-col"
                />
            </Card>
            <Card className={`col-span-12 ${expand.revenueByState ? '' : 'lg:col-span-6'} ${auth.user?.isSoftwareAffiliate ? 'order-1' : ''} ${expand.salesByContractor ? 'hidden' : ''}`}>
                <RevenueByState
                    onChange={handleComplete}
                    onExpand={handleExpand}
                    processing={processing.revenueByState}
                    isExpanded={expand.revenueByState}
                />
            </Card>

            <div className="col-span-full flex flex-col lg:flex-row gap-4 md:gap-6">
                <Card className={`relative card lg:max-w-full lg:w-96 ${expand.salesReport ? 'hidden' : ''}`}>
                    {auth.user?.isLenderAffiliate ? <MonitoringSummary
                        onChange={handleComplete}
                        processing={processing.monitoringSummary}
                    /> : <EligibleCommissionSummary
                        onChange={handleComplete}
                        processing={processing.eligibleCommissionSummary}
                    />}
                </Card>
                <Card className="overflow-hidden grow">
                    <GraphReport
                        className="h-full flex flex-col"
                        processing={processing.salesReport}
                        onChange={handleComplete}
                        onExpand={handleExpand}
                        isExpanded={expand.salesReport}
                    />
                </Card>
            </div>
        </div>
    </AuthLayout>
}

export default AffiliateDashboard
